import React, { useState, useEffect } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { isAdmin } from "../../../../hooks/permissions";

// Actions
import { success } from "../../../../redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "../../../../utils/message";
import { dateFormat } from "../../../../utils/date-format";
import { copyToClipboard } from "../../../../utils/helperFunctions";

// custom hook
import useScreenWidth from "../../../../hooks/useScreenWidth";

// MUI Components
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Typography,
  Tooltip,
  Stack,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

// Icon
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

// Images
import Hide from "../../../../assets/images/settings/hide.svg";
import Show from "../../../../assets/images/settings/show.svg";
import Copy from "../../../../assets/images/settings/copy.svg";
import Rotate from "../../../../assets/images/settings/rotate.svg";
import Delete from "../../../../assets/images/settings/delete.svg";
import Edit from "../../../../assets/images/accounts/editnew.png";
import NoContact from "../../../../assets/images/contacts/no-contact.svg";

// Styles
import "./styles.scss";
import { displayTooltip } from "../../../../hooks/displayTooltip";
import { stringLength } from "../../../../utils/helperFunctions";
import Loader from "../../../General/Loader";

const index = (props) => {
  const {
    setDeleteApiModal,
    setRotateApiModal,
    setSelectedApi,
    updatePagination,
    setEditApiModal,
    setSelectedApiKeyData,
  } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showApiKey, setShowApiKey] = useState([]);
  const rows = useSelector((state) => state.apiKeysReducers.apiKeys);
  const rowsCount = useSelector((state) => state.apiKeysReducers.rowsCount);
  const to = useSelector((state) => state.apiKeysReducers.to);
  const from = useSelector((state) => state.apiKeysReducers.from);
  const lastPage = useSelector((state) => state.apiKeysReducers.lastPage || 1);
  const currentPage = useSelector(
    (state) => state.apiKeysReducers.currentPage || 1
  );
  const isLoading = useSelector((state) => state.apiKeysReducers.loading);

  const dispatch = useDispatch();

  const handleShow = (id) => {
    setShowApiKey((prev) => [...prev, id]);
  };

  const handleHide = (id) => {
    const value = showApiKey.filter((key) => key !== id);
    setShowApiKey(value);
  };

  const handleDelete = (id) => {
    setSelectedApi(id);
    setDeleteApiModal(true);
  };

  const handleEdit = (data) => {
    setSelectedApiKeyData(data);
    setSelectedApi(data.id);
    setEditApiModal(true);
  };
  
  const handleRotate = (id) => {
    setSelectedApi(id);
    setRotateApiModal(true);
  };

  const handleChangePage = (event, newPage) => {
    updatePagination({
      page: newPage,
      pageSize: 10,
    });
  };

  const copyKey = (key) => {
    copyToClipboard(key);
    dispatch(success(MESSAGES.SETTINGS.API_KEYS.COPY_KEY));
  };

  const isTooltip = displayTooltip();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [activeRowId, setActiveRowId] = useState({});
  const isScreen = useScreenWidth();

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 220,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.name}`) ? (
          <Tooltip title={params.row.name} disableHoverListener={isTooltip}>
            <Box className="addressWrapper">
              <Typography>{params.row.name}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper">
            <Typography>{params.row.name}</Typography>
          </Box>
        ),
    },
    {
      field: "key",
      headerName: "Key",
      width: 220,
      height: 500,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="secretKey">
          <Tooltip
            title={showApiKey.includes(params.row.id) ? params?.row?.key : ""}
            disableHoverListener={isTooltip}
          >
            <input
              type={showApiKey.includes(params?.row?.id) ? "text" : "password"}
              value={
                showApiKey.includes(params?.row?.id)
                  ? params?.row?.key
                  : params?.row?.key?.slice(0, 13)
              }
              readOnly
            />
          </Tooltip>
          <Box className="hideShow">
            {showApiKey.includes(params.row.id) ? (
              <img
                src={Hide}
                alt="hide"
                onClick={() => handleHide(params.row.id)}
              />
            ) : (
              <img
                src={Show}
                alt="show"
                onClick={() => handleShow(params.row.id)}
              />
            )}
            <img
              src={Copy}
              alt="hide"
              onClick={() => copyKey(params?.row?.key)}
            />
          </Box>
        </Box>
      ),
    },
    {
      field: "source",
      headerName: "Source",
      width: 220,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.source ? params.row.source : "-"}</Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 220,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{dateFormat(params.row.createdAt)}</Typography>
        </Box>
      ),
    },
    {
      field: "expiredAt",
      headerName: "Expiry Date",
      width: 220,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>
            {params.row.expiredAt === null
              ? MESSAGES.SETTINGS.API_KEYS.NO_EXPIRY
              : dateFormat(params.row.expiredAt)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "lastUsedAt",
      headerName: "Last Used Date",
      width: 220,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>
            {params.row.lastUsedAt === null
              ? " -"
              : dateFormat(params.row.lastUsedAt)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 220,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <>
          {isScreen ? (
            <Box className="webhookTableActions">
              <Tooltip title="Edit Key">
                <img
                  src={Edit}
                  height={20}
                  alt="edit"
                  onClick={() => handleEdit(params.row)}
                />
              </Tooltip>
              <Tooltip title="Rotate Key">
                <img
                  src={Rotate}
                  alt="rotate"
                  onClick={() => handleRotate(params.row.id)}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <img
                  src={Delete}
                  alt="delete"
                  onClick={() => handleDelete(params.row.id)}
                />
              </Tooltip>
            </Box>
          ) : (
            <Box>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                  setActiveRowId(params.row); // Set active row ID when the menu is opened
                  handleClick(event);
                }}
              >
                <MoreVertIcon
                  sx={{
                    color:
                      activeRowId.id === params.row.id && anchorEl
                        ? "#ed5c2f"
                        : "inherit",
                  }}
                />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxWidth: "100px",
                    boxShadow: "0px 0px 5px -3px rgba(0,0,0,0.2)",
                  },
                  className: "actionDropdown",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleEdit(activeRowId);
                    handleClose();
                  }}
                >
                  <img
                  src={Edit}
                  height={20}
                  alt="edit"
                />
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleRotate(activeRowId.id);
                    handleClose();
                  }}
                >
                  <img src={Rotate} alt="rotate" />
                  Rotate
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDelete(activeRowId.id);
                    handleClose();
                  }}
                >
                  <img src={Delete} alt="delete" />
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        marginTop: "25px",
      }}
      className="APITableWrapper"
    >
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        rowCount={rowsCount}
        paginationMode="server"
        hideFooterSelectedRowCount
        hideFooterPagination
        rowSelection={false}
        getRowId={(row) => row.id}
        disableColumnMenu={true}
        className="APITableGrid"
        slots={{
          noRowsOverlay: () => (
            <Stack className="noRowsTextContacts">
              <img src={NoContact} alt="NoContact" />
              {MESSAGES.NO_ROWS_TEXT}
            </Stack>
          ),
          noResultsOverlay: () => (
            <Stack className="noRowsTextContacts">
              <img src={NoContact} alt="NoContact" />
              {MESSAGES.NO_ROWS_TEXT}
            </Stack>
          ),
          loadingOverlay: Loader,
        }}
      />
      <Box className="APITablePagination">
        <Pagination
          count={lastPage}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
          page={currentPage}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: ArrowBackIosNewIcon,
                next: ArrowForwardIosIcon,
              }}
              {...item}
            />
          )}
        />
        <Typography>
          Showing {rowsCount ? from : 0} to {rowsCount < to ? rowsCount : to} of{" "}
          {rowsCount} results
        </Typography>
      </Box>
    </div>
  );
};

export default index;
