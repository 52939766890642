import React, { useEffect, useRef, useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import {
  fetchAdminUsers,
  fetchAdminUsersFilters,
} from "../../../redux/actions/admin-users-actions";
import { CLEAR_ADMIN_USERS_FILTERS } from "../../../redux/actions/action-types";

//Utils
import { DEFAULT_PAGINATION } from "../../../utils/constants";
import { MESSAGES } from "../../../utils/message";

// Components
import Table from "./Table";
import AdvanceFilters from "./Filters";
import ActionModal from "./ActionModal";
import AddFundsModal from "./AddFundsModal";
import NoAdminUsersScreen from "./NoAdminUsersScreen";

// MUI Table
import {
  Container,
  Box,
  Grid,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// Icons
import Search from "../../../assets/images/contacts/contact-search.svg";
import ActiveFilter from "../../../assets/images/contacts/filter-2.svg";
import InactiveFilter from "../../../assets/images/contacts/filter.svg";

// Styles
import "./styles.scss";
import { isNumberString } from "../../../utils/helperFunctions";

const AdminUsers = () => {
  const { userId } = useParams();
  // Use the useMediaQuery hook to check the screen size
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  // Determine the maxWidth based on the screen size
  const maxWidth = isXlScreen ? "xxl" : "xl";

  const [isMounted, setIsMounted] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [search, setSearch] = useState("");
  const [searchApplied, setSearchApplied] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [openAdvanceFilters, setOpenAdvanceFilters] = useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [openFundsModal, setOpenFundsModal] = useState(false);

  const childRef = useRef();
  const dispatch = useDispatch();

  const applyFilters = useSelector(
    (state) => state.adminUsersReducers.applyFilters
  );
  const filters = useSelector((state) => state.adminUsersReducers.filters);
  const adminUsers = useSelector((state) => state.adminUsersReducers.totalUsersInDB);
  const filterSize = useSelector(
    (state) => state.adminUsersReducers.filterSize
  );

  const handleSearch = () => {
    if (search) {
      setPagination({ ...pagination, page: 0 });
      setSearchApplied(true);
      getAdminUsers();
    }
  };

  const searchKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const getAdminUsers = () => {
    let payload = {
      pagination,
      search: userId ? userId : search.length ? search : null,
      applyFilters: applyFilters,
      filters: applyFilters ? filters : null,
    };
    dispatch(fetchAdminUsers(payload));
  };

  useEffect(() => {
    setIsMounted(true);
    userId && setSearch(userId);
    userId && setSearchApplied(true);
    userId ? getAdminUsers(userId) : getAdminUsers();
    dispatch(fetchAdminUsersFilters());
    return () => {
      dispatch({ type: CLEAR_ADMIN_USERS_FILTERS });
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      getAdminUsers();
    }
  }, [pagination.page, pagination.pageSize, filterSize, filters]);

  useEffect(() => {
    if (!search && isMounted) {
      setSearchApplied(false);
      setSearch("");
      getAdminUsers();
    }
  }, [search]);

  const noAdminUsers = () => {
    return (
      <Container
        maxWidth={maxWidth}
        sx={{
          margin: "120px auto 50px",
        }}
      >
        <NoAdminUsersScreen />
      </Container>
    );
  };

  if(adminUsers === 0 && adminUsers !== null) 
  {
    return  noAdminUsers();
  }

  return (
    <>
      <Container maxWidth="xxl">
        <div className="adminUserMain">
          <Grid container>
            <Grid
              item
              lg={8}
              md={8}
              sm={12}
              xs={12}
              className="searchBtnWrapper"
            >
              <Box className="searchWrapper">
                <Input
                  className={`searchInput ${
                    searchApplied ? "searchApplied" : ""
                  }`}
                  type="text"
                  value={search}
                  onChange={(e) => {
                    if (
                      e.target.value.length > 9 &&
                      isNumberString(e.target.value)
                    )
                      return;
                    e.target.value == " "
                      ? setSearch(e.target.value.trim())
                      : setSearch(e.target.value);
                  }}
                  onKeyDown={searchKeyDown}
                  placeholder={
                    MESSAGES.ADMIN_DASHBOARD.USERS.SEARCH_PLACE_HOLDER
                  }
                  endAdornment={
                    <InputAdornment
                      position="end"
                      className="searchInputAdornment"
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        className="searchIcon"
                        onClick={handleSearch}
                      >
                        <img src={Search} alt="search" />
                      </IconButton>
                      {searchApplied && (
                        <HighlightOffIcon
                          onClick={() => {
                            setSearch("");
                          }}
                          className="clearSerach"
                        />
                      )}
                    </InputAdornment>
                  }
                />
                <Box className="filterBtnWrapper">
                  <img
                    onClick={() => {
                      setOpenAdvanceFilters(true);
                    }}
                    className={
                      filterSize > 0
                        ? "filterImage activeFilter"
                        : "filterImage noFilter"
                    }
                    src={filterSize > 0 ? ActiveFilter : InactiveFilter}
                    alt="Filter"
                  />
                  {filterSize > 0 && (
                    <Typography className="filtersCounter">
                      {filterSize}
                    </Typography>
                  )}
                </Box>
                {filterSize > 0 && (
                  <Button
                    onClick={() => {
                      childRef.current.clearFilters();
                    }}
                    className="clearFilterBtn"
                  >
                    {MESSAGES.ADMIN_DASHBOARD.USERS.CLEAR_FILTER_BTN_TEXT}
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
          <Table
            pagination={pagination}
            updatePagination={setPagination}
            setOpenActionModal={setOpenActionModal}
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
            setOpenFundsModal={setOpenFundsModal}
            searchApplied={searchApplied}
          />
          <AdvanceFilters
            open={openAdvanceFilters}
            handleCloseFilter={() => setOpenAdvanceFilters(false)}
            ref={childRef}
            updatePagination={setPagination}
          />
          <ActionModal
            openModal={openActionModal}
            handleCloseModal={() => setOpenActionModal(false)}
            selectedUser={selectedUser}
            getAdminUsers={getAdminUsers}
          />
          <AddFundsModal
            open={openFundsModal}
            handleClose={() => {
              setOpenFundsModal(false);
            }}
            selectedUser={selectedUser}
          />
        </div>
      </Container>
    </>
  );
};

export default AdminUsers;
