import React from "react";

// Utils
import { dateFormat } from "../../../../utils/date-format";
import { MESSAGES } from "../../../../utils/message";
import { getEvent, isSlackWebhook } from "../../../../utils/helperFunctions";

// MUI Components
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Divider,
} from "@mui/material";

// MUI Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// styles
import "./styles.scss";
import Back from "../../../../assets/images/webhooks/back.svg";


const WebhookLog = (props) => {
  const { selectedWebhookLog, setDisplayLogPage } = props;

  const renderDataFromBody = (selectedWebhookLog, type) => {
    let parsedBody = null;

    if (isSlackWebhook(selectedWebhookLog?.value?.[type]?.url)) {
      parsedBody = JSON.parse(
        JSON.parse(selectedWebhookLog?.value?.[type]?.body)?.text
      );
    } else {
      parsedBody = selectedWebhookLog?.value?.[type]?.body;
    }

    if (
      !parsedBody ||
      !parsedBody.data ||
      Object.keys(parsedBody.data).length === 0
    ) {
      return " ";
    }

    return Object.keys(parsedBody.data).map((key) => (
      <div key={key}>
        {key}: {parsedBody.data[key]}
      </div>
    ));
  };

  return (
    <Container maxWidth="xxl">
      <Grid container className="webhookLogGrid">
        <Grid item lg={1} md={1} sm={2} xs={2}>
          <Button
            className="webhookBackBtn"
            onClick={() => setDisplayLogPage(false)}
          >
            <img src={Back} alt="icon" />
            <Typography>
              {MESSAGES.SETTINGS.WEBHOOKS.LOGS.BACK_BUTTON}
            </Typography>
          </Button>
        </Grid>
        <Grid paddingLeft={"30px"} item lg={11} md={11} sm={9} xs={9}>
          <Box className="detailsHeading">
            <Typography>{MESSAGES.SETTINGS.WEBHOOKS.LOGS.TITLE}</Typography>
            <Box className="detailsWrapper">
              <Typography>
                {MESSAGES.SETTINGS.WEBHOOKS.LOGS.EVENT_TITLE}
              </Typography>
              <Typography>{getEvent(selectedWebhookLog)}</Typography>
            </Box>
            <Box className="detailsWrapper">
              <Typography>
                {MESSAGES.SETTINGS.WEBHOOKS.LOGS.STATUS_TITLE}
              </Typography>
              <Typography>
                {selectedWebhookLog?.value?.response?.status || " -"}
              </Typography>
            </Box>
            <Box className="detailsWrapper">
              <Typography>
                {MESSAGES.SETTINGS.WEBHOOKS.LOGS.DATE_TITLE}
              </Typography>
              <Typography>
                {dateFormat(
                  selectedWebhookLog?.value?.response?.headers?.date
                ) || " -"}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider className="webhookLogDivider" />
      <Grid container>
        <Grid item lg={1}></Grid>
        <Grid paddingLeft={"30px"} item lg={11} md={11} sm={12} xs={12}>
          {selectedWebhookLog?.value?.request?.body && (
            <Box className="requestBody">
              <Typography>
                {MESSAGES.SETTINGS.WEBHOOKS.LOGS.REQUEST_TITLE}
              </Typography>
              <Box className="objectWrapper">
                <pre>{renderDataFromBody(selectedWebhookLog, "request")}</pre>
              </Box>
            </Box>
          )}
          {selectedWebhookLog?.value?.response?.body && (
            <Box className="requestBody">
              <Typography>
                {MESSAGES.SETTINGS.WEBHOOKS.LOGS.RESPONSE_TITLE}
              </Typography>
              <Box className="objectWrapper">
                <pre>{renderDataFromBody(selectedWebhookLog, "response")}</pre>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default WebhookLog;
