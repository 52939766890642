// Request util for api call
import request from "../../utils/request";

// Actions Types
import { SET_ADMIN_USERS_DATA, SET_ADMIN_USERS_FILTERS_LABELS, SET_ADMIN_USERS_LOADER, } from "./action-types";

// Utiils
import { dateFormat } from "../../utils/date-format";
import { DEFAULT_PAGINATION, USER_ROLES } from "../../utils/constants";
import { balanceFormatter } from "../../utils/helperFunctions";
import { setImpersonatedUser } from "../../utils/auth";



const fetchAdminUsers = (payload) => async (dispatch) => {
    try {
        dispatch({ type: SET_ADMIN_USERS_LOADER, payload: true });

        let url = 'admin/users';

        let queryPayload = {
            page: payload?.pagination?.page ? payload.pagination.page : DEFAULT_PAGINATION.page,
            pageSize: payload?.pagination?.pageSize ? payload.pagination.pageSize : DEFAULT_PAGINATION.pageSize,
        }

        if (payload?.search?.length) {
            queryPayload.search = payload.search;
        }

        if (payload?.applyFilters) {
            const filterParams = Object.entries(payload.filters)
                .map(([key, value]) => {
                    if (key === 'signUpDate') {
                        // If the value is an object, iterate over its properties
                        if (value) {
                            let date = Object?.entries(value)
                                .map(([nestedKey, nestedValue]) => `${JSON.stringify(nestedKey)}:${JSON.stringify(nestedValue)}`)
                                .join(', ');
                            queryPayload[key] = `{${date}}`;
                        }
                    }
                    else if (value.length) {
                        queryPayload[key] = `${JSON.stringify(value)}`;
                    }
                    else if (typeof value === "boolean") {
                        // Handle boolean values explicitly
                        queryPayload[key] = value;
                    }
                })
        }

        const response = await request.get(url, queryPayload);

        if (response.status === 200) {
            const combinedData = response.data.data.rows.map((user, i) => ({
                key: i,
                id: user.id,
                ownerId: user['organization.ownerId'],
                company: user.companyName,
                name: user.firstName + ' ' + user.lastName,
                email: user.email,
                role: USER_ROLES.find((role) => {
                    return user['role.slug'] === role.value;
                })?.title || user['role.slug'],
                plan: user['organization.plan.name'],
                orders: user.ordersCount,
                isSystemAdmin: user.isSystemAdmin,
                revenue: user.revenue ? balanceFormatter(user.revenue) : " -",
                status: user.status,
                signUpDate: dateFormat(user.createdAt, true),
                updatedAt: dateFormat(user.lastLoginAt, true),
            }));
            const orders = {
                rows: combinedData,
                count: response.data.data.count,
                totalRecordsInDB: response.data.data.totalRecordsInDB,
                to: response.data.data.to,
                from: response.data.data.from,
                currentPage: response.data.data.currentPage,
                lastPage: response.data.data.lastPage,
                perPage: response.data.data.perPage,
            }
            dispatch({ type: SET_ADMIN_USERS_DATA, payload: orders });
        }
    } catch (error) {
        return error;
    } finally {
        dispatch({ type: SET_ADMIN_USERS_LOADER, payload: false });
    }
}

const fetchAdminUsersFilters = () => async (dispatch) => {
    try {
        const filters = await request.get("admin/users/filters-data");
        let modifiedLabels = {}
        Object.keys(filters.data.data).forEach((key) => {
            if (typeof filters.data.data[key] === 'object') {
                const combinedData = Object.entries(filters.data.data[key]).map(([valueKey, item], id) => ({
                    id,
                    title: item,
                    value: valueKey || item,
                }));
                modifiedLabels[key] = combinedData;
            }

        });
        dispatch({ type: SET_ADMIN_USERS_FILTERS_LABELS, payload: modifiedLabels });
    } catch (error) {
        return error;
    }
}

const impersonateUser = (payload) => async (dispatch) => {
    try {
        const user = await request.get(`/admin/users/login-as/${payload}`);
        if (user.status === 200) {
            setImpersonatedUser(user.data.data);
        }
        return user;
    } catch (error) {
        return error;
    }
}

const updateUserStatusByAdmin = (payload) => async (dispatch) => {
    try {
        const user = await request.post(`/admin/users/update-status`, payload);
        return user;
    } catch (error) {
        return error.response;
    }
}

const addFundsToUserByAdmin = (payload) => async (dispatch) => {
    try {
        const user = await request.post(`/admin/users/add-funds`, payload);
        return user;
    } catch (error) {
        return error.response;
    }
}


export { fetchAdminUsers, fetchAdminUsersFilters, impersonateUser, updateUserStatusByAdmin, addFundsToUserByAdmin }
